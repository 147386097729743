import { Box } from '@dtx-company/design-system/src'
import { Days } from '../types/days'
import { FC } from 'react'
import { HoursType } from './StoreHours.types'
import { Text } from '@dtx-company/shared-components/src/components/atoms/Text/index'
import { capitalize } from '@dtx-company/true-common/src/utils/strings'
import { formatTime } from '../../../utils/time'
import { styled } from '@mui/material'
import { theme } from '@dtx-company/shared-components/src/'
import { weekdays } from './constants'

const _DaysTable = styled(Box)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-right: 8px; // For chevron indicating collapsed / expended state
`

export const HoursDisplay: FC<{
  isClosedNow: boolean
  hours: { [key in Days]: HoursType }
  abbrevTimeZone: string
  defaultColor: string
  todaysDay: string
}> = ({ isClosedNow, hours, abbrevTimeZone, todaysDay, defaultColor }) => {
  return (
    <_DaysTable>
      {weekdays.map((day: Days, idx) => {
        const dayHours = hours[day]
        const dayIsToday = day === todaysDay
        const formattedTimeZone = abbrevTimeZone ? `(${abbrevTimeZone})` : ''
        const formattedTime = `${formatTime(dayHours.open)} - ${formatTime(
          dayHours.close
        )} ${formattedTimeZone}`

        let color = defaultColor
        if (dayIsToday) color = theme.colors.masterBuilder.green30
        if (dayIsToday && isClosedNow) color = theme.colors.status.errorDark

        return (
          <Box
            key={`${day}-${idx}`}
            sx={{
              display: 'grid',
              gridTemplateColumns: '1fr 4fr',
              alignContent: 'space-between',
              gap: 4
            }}
          >
            <Text variant="body/medium" fontWeight={600} color={color}>
              {capitalize(day)}
            </Text>
            <Text variant="body/medium" fontWeight={600} color={color} textAlign={'left'}>
              {dayHours.active ? formattedTime : 'Closed'}
            </Text>
          </Box>
        )
      })}
    </_DaysTable>
  )
}
